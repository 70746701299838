import { AppBar as MUIAppBar, Button, Container, Menu, MenuItem, Toolbar } from '@material-ui/core';
import { AccountCircle, ExpandMore } from '@material-ui/icons';
import React, { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AuthContext } from '../../App';
import { client } from '../../utils/client';
import { routes } from '../../utils/constants';
import { useStyles } from './styles';

const menuOptions = [
  {
    label: 'My Publications',
    paths: routes.publications.routeProps.path,
    items: [
      {
        label: 'Active',
        path: routes.publications.active.routeProps.path[0],
      },
      {
        label: 'Archived',
        path: routes.publications.archived.routeProps.path[0],
      },
    ],
  },
  {
    label: 'Tasks',
    paths: routes.tasks.routeProps.path,
    items: [
      { label: 'Open', path: routes.tasks.open.routeProps.path[0] },
      { label: 'Closed', path: routes.tasks.closed.routeProps.path[0] },
    ],
  },
];

export const AppBar = () => {
  const classes = useStyles();

  const [token] = useContext(AuthContext);
  const history = useHistory();

  const isProfilePath = !!useRouteMatch('/profile*');

  const handleLogout = async () => {
    history.push(routes.logout.routeProps.path[0]);
  };

  return (
    <MUIAppBar position="sticky">
      <Container>
        <Toolbar>
          <div style={{ flex: 1 }}>
            {!!token &&
              menuOptions.map(({ label, items, paths }, key) => (
                <MenuButton
                  key={key}
                  label={label}
                  paths={paths}
                  menuItems={items.map((item) => ({
                    label: item.label,
                    onClick: () => history.push(item.path),
                  }))}
                />
              ))}
          </div>
          {token && (
            <>
              <Button
                className={isProfilePath ? classes.selectedItem : classes.unselectedItem}
                onClick={() => {
                  history.push(routes.profile.routeProps.path[0]);
                }}
              >
                <AccountCircle />
              </Button>
              <Button className={classes.unselectedItem} onClick={handleLogout} style={{ fontWeight: 'bold' }}>
                Logout
              </Button>
            </>
          )}
          {/* {!token && (
            <Button className={classes.unselectedItem} onClick={handleLogin} style={{ fontWeight: 'bold' }}>
              Login
            </Button>
          )} */}
        </Toolbar>
      </Container>
    </MUIAppBar>
  );
};

interface MenuButtonProps {
  label: string;
  selected?: boolean;
  paths: string[];
  menuItems: Array<{ onClick: () => void; selected?: boolean; label: string }>;
}

const MenuButton = (props: MenuButtonProps) => {
  const classes = useStyles();
  const { label, menuItems, paths } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isPathMatch = !!useRouteMatch(paths);

  return (
    <>
      <Button
        className={isPathMatch ? classes.selectedItem : classes.unselectedItem}
        onClick={handleClick}
        endIcon={<ExpandMore />}
        style={{ fontWeight: 'bold' }}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {menuItems?.map((menuItem) => (
          <MenuItem
            key={menuItem.label}
            selected={menuItem.selected}
            onClick={() => {
              menuItem.onClick();
              handleClose();
            }}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
