import jsforce from 'jsforce';
import {
  ApiConstructorOptions,
  CursorResponse,
  GetProfileResponse,
  PublicationDetailOptions,
  PublicationDetailResponse,
  PublicationTaskCloseOptions,
  SupportingDocumentDownloadLinkOptions,
  SupportingDocumentDownloadLinkResponse,
  UpdateProfileRequest,
  UpdateProfileResponse,
  UploadContentVersionOptions,
} from '../typings';
import { convertFileToBase64 } from './format';

/**
 * Api class for sending requests
 */
export class Api {
  private conn: jsforce.Connection;
  private resetPasswordPage: string;

  constructor(opts: ApiConstructorOptions) {
    const {
      clientId,
      salesforceApiVersion = '53.0',
      loginUrl,
      redirectUri,
      instanceUrl,
      resetPasswordPage = 'mvn__ResetPassword',
    } = opts;

    this.conn = new jsforce.Connection({ loginUrl, version: salesforceApiVersion, clientId, redirectUri, instanceUrl });
    this.resetPasswordPage = resetPasswordPage;
  }

  /**
   * Returns the Document Download URL
   * @param idOrPath the path to the file or id
   * @returns the full URL to download the file
   */
  getDocumentDownloadUrl = (idOrPath: string) => {
    if (idOrPath.startsWith('http') && idOrPath.includes('/sfc/servlet')) {
      const index = idOrPath.indexOf('/sfc/servlet');
      return `${this.conn.instanceUrl}${idOrPath.slice(index)}`;
    } else if (idOrPath.includes('/')) {
      return `${this.conn.instanceUrl}${idOrPath}`;
    } else {
      return `${this.conn.instanceUrl}/sfc/servlet.shepherd/document/download/${idOrPath}`;
    }
  };

  /**
   * Get & return the token from Salesforce
   * @param code the authorization code
   * @returns the token to persist
   */
  authorize = async (code: string): Promise<Record<string, string | undefined>> => {
    await this.conn.authorize(code);

    return {
      instanceUrl: this.conn.instanceUrl,
      accessToken: this.conn.accessToken,
      refreshToken: this.conn.refreshToken,
    };
  };

  /**
   * Update the jsforce token
   * @param props the jsforce token
   */
  setToken = (props: jsforce.ConnectionOptions) => {
    this.conn = new jsforce.Connection({ ...this.conn.oauth2, ...props });
  };

  /**
   * Revoke the token
   */
  logout = async (): Promise<string> => {
    await this.conn.logout();

    const retUrl = encodeURIComponent(this.conn.oauth2.loginUrl);
    return `${this.conn.oauth2.loginUrl}/secur/logout.jsp?retUrl=${retUrl}`;
  };

  /**
   * Returns the Change Password URL
   * @returns reset password url
   */
  getChangePassword = (): string => {
    return `${this.conn.oauth2.loginUrl}/${this.resetPasswordPage}`;
  };

  /**
   * Returns the Authorization URL
   * @returns the url
   */
  getAuthorizationUrl = () => {
    return this.conn.oauth2.getAuthorizationUrl({ scope: 'api id web' });
  };

  /**
   * Executes a request to Salesforce
   * @param info
   * @returns the response
   */
  do = async <T>(info: string | jsforce.RequestInfo) => {
    let request: string | jsforce.RequestInfo;

    if (typeof info === 'string') {
      request = this.conn.instanceUrl + info;
    } else {
      request = { ...info, url: this.conn.instanceUrl + info.url };
    }

    try {
      const response = await this.conn.request<T>(request);
      return response;
    } catch (error) {
      if (error instanceof Error && error.message === 'Access Declined') {
        window.location.href = this.getAuthorizationUrl();
      }

      console.log('error! -', error);
      throw error;
    }
  };

  /**
   * Returns the running user profile
   * @returns the profile
   */
  getProfile = async (): Promise<GetProfileResponse> => {
    const url = `/services/apexrest/mvn/pubs/profile`;

    return this.do<GetProfileResponse>(url);
  };

  /**
   * Updates the User Profile
   * @param request the request
   * @returns the outcome
   */
  updateProfile = async (request: UpdateProfileRequest) => {
    const url = `/services/apexrest/mvn/pubs/profile`;
    const body = JSON.stringify(request);

    return this.do<UpdateProfileResponse>({ method: 'POST', url, body });
  };

  /**
   * Returns a single publication
   * @param opts the options
   * @returns the publication
   */
  getPublication = async (opts: PublicationDetailOptions): Promise<PublicationDetailResponse> => {
    const url = `/services/apexrest/mvn/pubs/publications/${encodeURIComponent(opts.id)}`;

    return this.do<PublicationDetailResponse>(url);
  };

  /**
   * Returns the supporting document download link
   * @param opts the options
   * @returns the download link
   */
  getSupportingDocumentDownloadLink = async (opts: SupportingDocumentDownloadLinkOptions) => {
    const publicationId = encodeURIComponent(opts.publicationId);
    const documentId = encodeURIComponent(opts.documentId);
    const url = `/services/apexrest/mvn/pubs/publications/${publicationId}/supportingDocuments/${documentId}/requestDownload`;

    return this.do<SupportingDocumentDownloadLinkResponse>({ method: 'POST', url, body: '' });
  };

  /**
   * Returns a cursor response
   * @param path the url path
   * @param params the options
   * @returns the cursor
   */
  list = async <T>(path: string, params?: any): Promise<CursorResponse<T>> => {
    let url = `/services/apexrest/mvn/pubs/${path}${this.appendParams(params)}`;

    return this.do(url);
  };

  /**
   * Uploads a Content Version Document
   * @param opts the options
   * @returns the upload confirmation
   */
  uploadContentVersion = async (opts: UploadContentVersionOptions): Promise<jsforce.RecordResult> => {
    const params = {
      PathOnClient: opts.pathOnClient,
      ReasonForChange: opts.reasonForChange ?? '',
      VersionData: await convertFileToBase64(opts.file),
      mvn__PP_Source__c: 'Collaborator Portal',
      mvn__PP_Pending_Parenting__c: true,
    };

    return this.conn.sobject('ContentVersion').create(params);
  };

  /**
   * Closes a Task record
   * @param opts the options
   * @returns the operation outcome
   */
  closeTask = async (opts: PublicationTaskCloseOptions) => {
    const { id, verdict, comments, uploadedFiles } = opts;
    const taskId = encodeURIComponent(id);
    const url = `/services/apexrest/mvn/pubs/tasks/${taskId}/closeTask`;
    // @ts-ignore
    const revisionIds = uploadedFiles && uploadedFiles.map((file) => file.value?.id).filter((id): id is string => !!id);

    const body = JSON.stringify({ verdict, comments, revisionIds });

    return this.do<PublicationTaskCloseOptions>({ method: 'POST', url, body });
  };

  /**
   * Combines URL params & returns a query string
   * @param params the query string params
   * @returns the query string
   */
  private appendParams = (params?: Record<string, any>) => {
    if (!params) {
      return '';
    }

    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value);
    }

    return '?' + searchParams.toString();
  };
}
