import { createTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const theme = createTheme({
  typography: {
    fontFamily: ['Noto Sans', 'sans-serif'].join(','),
    overline: { fontWeight: 700 },
    h4: {
      fontSize: 24,
      fontWeight: 600,
    },
    h5: {
      fontSize: 20,
      fontWeight: 300,
    },
  },
  palette: {
    background: {
      default: '#FFF',
      paper: '#FFF',
    },
    action: {
      disabled: grey[500],
    },
    primary: {
      main: '#201332',
    },
    secondary: {
      main: '#7C3FF8',
      contrastText: '#fff',
    },
  },

  overrides: {
    MuiSelect: {
      root: {
        backgroundColor: '#FFF',
      },
    },
    MuiChip: {
      root: {
        fontFamily: 'NotoSans-SemiBold',
        fontSize: 14,
      },
    },
    MuiCard: {
      root: {
        boxShadow: '0px 24px 24px -16px rgba(219, 217, 234, 0.38)',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

export default theme;
