import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { CLIENT_ID, INSTANCE_URL } from '../utils/constants';

type VerifyConfigurationProps = {
  children: React.ReactNode;
};

export const VerifyConfiguration = (props: VerifyConfigurationProps) => {
  const hasClientId = CLIENT_ID;
  const hasInstanceUrl = INSTANCE_URL;

  if (!hasClientId || !hasInstanceUrl) {
    return (
      <Box>
        <Alert severity="error" color="error">
          <strong>Missing environment variables -</strong> The following environment variables must be defined:
          <br />
          <ul>
            {!hasClientId && (
              <li>
                <code>REACT_APP_PORTAL_AUTH_CLIENT_ID</code>
              </li>
            )}
            {!hasInstanceUrl && (
              <li>
                <code>REACT_APP_PORTAL_SALESFORCE_INSTANCE_URL</code>
              </li>
            )}
          </ul>
        </Alert>
      </Box>
    );
  } else {
    return <>{props.children}</>;
  }
};
