import { Grid, Paper, Typography } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { BackdroppedCircularProgress } from '../../components';
import { client } from '../../utils/client';
import { routes } from '../../utils/constants';
import { ProfileForm } from './ProfileForm';

const Profile = () => {
  const match = useRouteMatch<{ id: string }>();

  const [{ loading, value: profile }, doGetProfile] = useAsyncFn(() => client.getProfile(), [match.params.id]);

  useEffectOnce(() => {
    doGetProfile();
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          <Switch>
            <Route {...routes.profile.routeProps}>Profile Settings</Route>
          </Switch>
        </Typography>
        <Paper style={{ minHeight: 200 }}>
          <Grid container>
            <Grid item xs={12}>
              <BackdroppedCircularProgress open={loading} />
              {!loading && !!profile && <ProfileForm defaultValues={profile} />}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Profile;
