import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'black',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(8),
    },
    section: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        color: 'white',
      },
    },
  })
);
