import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import jsforce from 'jsforce';
import { useCallback, useEffect } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useAsync } from 'react-use';
import { FileIcon, UploadIcon } from '..';
import { client } from '../../utils/client';
import useStyles from './styles';

export type FileUploadItem = {
  file: File;
  loading: boolean;
  value?: jsforce.RecordResult;
  error?: Error;
};

interface UploadedFileProps {
  file: File;
  disabled: boolean;
  handleRemoveFile: (fileName: string) => void;
  handleFileUploadStateChange: (state: FileUploadItem) => void;
}

/**
 * @description Renders a file and uploads SF ContentVersion. Updates parent state via `handlefileUploadStateChange`
 *
 */
const UploadedFile = (props: UploadedFileProps) => {
  const theme = useTheme();
  const { file, disabled, handleRemoveFile, handleFileUploadStateChange } = props;

  const { loading, value, error } = useAsync(() => {
    return client.uploadContentVersion({
      contentDocumentId: '',
      file,
      pathOnClient: file.name,
      reasonForChange: '',
    });
  });

  useEffect(() => {
    handleFileUploadStateChange({ file, loading, value, error });
  }, [loading, value, file, error, handleFileUploadStateChange]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F7F7F7',
        paddingLeft: theme.spacing(1.5),
        border: '1px solid #e9e9e9',
        borderRadius: '2px',
      }}
      key={file.name}
    >
      <div style={{ marginRight: theme.spacing(2) }}>
        <FileIcon size={24} fileExtension={file.name.substring(file.name.lastIndexOf('.') + 1)} />
      </div>
      <Typography style={{ flexGrow: 1 }} variant="body2">
        {file.name}
      </Typography>
      {loading && <CircularProgress color={'secondary'} size={24} />}
      <IconButton
        disabled={loading || disabled}
        data-testid="remove-file"
        role="button"
        name="remove-file"
        onClick={() => handleRemoveFile(file.name)}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export interface DragAndDropZoneProps {
  disabled: boolean;
  uploadedFiles: Array<FileUploadItem>;
  setUploadedFiles: React.Dispatch<React.SetStateAction<Array<FileUploadItem>>>;
}

/**
 * @description Renders modal allowing user to complete a Task.
 *
 */
export const DragAndDropZone = (props: DragAndDropZoneProps) => {
  const { uploadedFiles, disabled, setUploadedFiles } = props;

  const classes = useStyles();
  const theme = useTheme();

  const onDrop = useCallback<Required<DropzoneOptions>['onDrop']>(
    (acceptedFiles) => {
      setUploadedFiles((uploadedFiles) => [
        ...uploadedFiles,
        ...acceptedFiles.map((file) => ({ file, loading: true, value: undefined })),
      ]);
    },
    [setUploadedFiles]
  );

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop,
    multiple: true,
  });

  const handleRemoveFile = useCallback(
    (fileName: string) => {
      setUploadedFiles((uploadedFiles) => uploadedFiles.filter((file) => file.file.name !== fileName));

      // should always be true, but we check to appease tslint
      if (inputRef?.current?.files?.length) {
        // we remove the file from the input to prevent memory leaks
        const dt = new DataTransfer();

        for (const file of Array.from(inputRef?.current?.files ?? [])) {
          file.name !== fileName && dt.items.add(file);
        }

        inputRef.current.files = dt.files;
      }
    },
    [inputRef, setUploadedFiles]
  );

  const handleFileUploadStateChange = useCallback<UploadedFileProps['handleFileUploadStateChange']>(
    (state) => {
      if (state.error) {
        handleRemoveFile(state.file.name);
      } else {
        setUploadedFiles((uploadedFiles) => {
          return uploadedFiles.map((uploadedFile) =>
            uploadedFile.file.name === state.file.name ? state : uploadedFile
          );
        });
      }
    },
    [handleRemoveFile, setUploadedFiles]
  );

  return (
    <>
      <div {...getRootProps()} className={classes.dropzoneRoot}>
        <input data-testid="dropzone-input" {...getInputProps()} />
        <UploadIcon stroke={theme.palette.text.secondary} size={30} />
        {isDragActive ? (
          <Typography color="textSecondary">Drop the files here ...</Typography>
        ) : (
          <Typography color="textSecondary">Drop files here or click to upload</Typography>
        )}
      </div>

      {!!uploadedFiles.length && (
        <div className={classes.uploadedFilesContainer}>
          {uploadedFiles.map((file) => (
            <UploadedFile
              disabled={disabled}
              key={file.file.name}
              file={file.file}
              handleRemoveFile={handleRemoveFile}
              handleFileUploadStateChange={handleFileUploadStateChange}
            />
          ))}
        </div>
      )}
    </>
  );
};
