import { Button, Grid, TextField, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { GetProfileResponse } from '../../typings';
import { client } from '../../utils/client';

const labels = {
  fieldRequired: 'This field is required.',
  invalidEmail: 'This needs to be a valid email.',
};

const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    actions: {
      '& > *': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(1),
      },
    },
  })
);

type ProfileFormProps = {
  defaultValues: GetProfileResponse;
};

export const ProfileForm = (props: ProfileFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState<boolean>(false);
  const classes = useStyles();
  const { register, formState, handleSubmit, reset } = useForm<GetProfileResponse>({
    defaultValues: props.defaultValues,
  });
  const { errors } = formState;

  const onSubmit = async (data: GetProfileResponse) => {
    try {
      await client.updateProfile(data);
      enqueueSnackbar('Profile has been updated.', { variant: 'success' });
      setEditMode(false);
    } catch (error) {
      enqueueSnackbar(`An error occured when updating the profile: ${error}`, { variant: 'error' });
    }
  };

  const onError = (e: any) => console.log('error', e);

  const handleChangePassword = () => {
    window.location.href = client.getChangePassword();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} className={classes.root}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              fullWidth
              color={'secondary'}
              {...register('firstName', { required: { value: true, message: labels.fieldRequired } })}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              fullWidth
              color={'secondary'}
              {...register('lastName', { required: { value: true, message: labels.fieldRequired } })}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email Address"
              type="email"
              fullWidth
              color={'secondary'}
              {...register('email', {
                required: { value: true, message: labels.fieldRequired },
                pattern: {
                  value: EMAIL_REGEX,
                  message: labels.invalidEmail,
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone Number"
              type="phone"
              fullWidth
              color={'secondary'}
              {...register('phone', { required: { value: true, message: labels.fieldRequired } })}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
        <div className={classes.actions} style={{ float: 'right' }}>
          <Button size="large" onClick={handleChangePassword} style={{ fontWeight: 'bold' }}>
            Change Password
          </Button>
          {!editMode && (
            <Button size="large" variant="outlined" onClick={() => setEditMode(true)}>
              Edit Profile
            </Button>
          )}
          {editMode && (
            <>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  reset();
                  setEditMode(false);
                }}
              >
                Cancel
              </Button>
              <Button size="large" color="secondary" variant="contained" type="submit">
                Save Profile
              </Button>
            </>
          )}
        </div>
      </form>
    </>
  );
};
