import { Box, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AppBar, AppFooter, VerifyConfiguration } from './components';
import { useToken } from './hooks/useToken';
import { Router } from './Router';
import theme from './utils/theme';

export const AuthContext = createContext(undefined as any);

function App() {
  const [token, setToken] = useToken();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthContext.Provider value={[token, setToken]}>
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <CssBaseline />
              <Box display={'flex'} minHeight={'100vh'} flexDirection={'column'} justifyContent={'space-between'}>
                <Box>
                  <AppBar />
                  <Box>
                    <Box margin="auto" maxWidth="1140px" p={3} pt={9} overflow="none">
                      <Router />
                    </Box>
                  </Box>
                </Box>
                <AppFooter />
              </Box>
            </BrowserRouter>
          </SnackbarProvider>
        </AuthContext.Provider>
      </ThemeProvider>
    </div>
  );
}

const Wrapper = () => (
  <VerifyConfiguration>
    <App />
  </VerifyConfiguration>
);

export default Wrapper;
