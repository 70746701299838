import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    selectedItem: {
      color: '#B086FF',
      borderBottomWidth: 3,
      borderBottomStyle: 'solid',
      borderBottomColor: '#B086FF',
      borderRadius: 0,
      height: 64,
    },
    unselectedItem: {
      color: theme.palette.primary.contrastText,
      height: 64,
    },
  })
);
