import {
  Box,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { DownloadIcon, EmptyContainer, FileIcon, OverflowTooltip } from '../../components';
import { PublicationSupportingDocument } from '../../typings';
import { client } from '../../utils/client';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: 'white',
  },
  documentCardSmallFileIcon: {
    paddingRight: theme.spacing(2),
  },
  documentCardDownloadIndicator: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    position: 'absolute',

    backgroundColor: '#111111',
    borderRadius: '3px',
    padding: '4px 8px',
    top: theme.spacing(2),
    right: theme.spacing(2),
    visibility: 'hidden',
    zIndex: 1, // ensures this is in "front" of the file svg
  },
  documentCardActionArea: {
    '&:hover $documentCardDownloadIndicator': {
      visibility: 'visible',
    },
  },
  documentCardLargeFileIconWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '154px',
    backgroundColor: 'rgba(245, 245, 245, 1)',
  },
}));

interface PublicationSupportingDocumentsProps {
  documents: Array<PublicationSupportingDocument>;
  publicationId: string;
}

/**
 * @description Renders a Publication's history as a timeline of document versions.
 *
 */
const PublicationSupportingDocuments = (props: PublicationSupportingDocumentsProps) => {
  const { documents, publicationId } = props;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleRequestDocument = (doc: PublicationSupportingDocument) => async () => {
    try {
      setLoading(true);
      enqueueSnackbar('Requesting download...', { variant: 'success', autoHideDuration: 3000 });

      const data = await client.getSupportingDocumentDownloadLink({
        documentId: doc.id,
        publicationId,
      });

      window.location.href = client.getDocumentDownloadUrl(data.downloadUrl);
    } catch (error) {
      enqueueSnackbar(`An error occured when downloading the supporting document: ${error}`, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={3}>
      {documents.map((doc) => (
        <Grid item sm={6} key={doc.id} xs={12} md={4}>
          <DocumentCard document={doc} loading={loading} handleOnClick={handleRequestDocument(doc)} />
        </Grid>
      ))}
      {documents.length === 0 && (
        <Grid item sm={12}>
          <EmptyContainer icon={'empty'} message={'Nothing to show.'} />
        </Grid>
      )}
    </Grid>
  );
};

interface DocumentCardProps {
  document: PublicationSupportingDocument;
  loading: boolean;
  handleOnClick: CardActionAreaProps['onClick'];
}

/**
 * @description renders a card displaying a supporting document and allows download on click
 */
const DocumentCard = (props: DocumentCardProps) => {
  const classes = useStyles();
  const { document, loading, handleOnClick } = props;

  return (
    <Card>
      <CardActionArea onClick={handleOnClick} classes={{ root: classes.documentCardActionArea }}>
        <div className={classes.documentCardDownloadIndicator}>
          <Box mr={0.5}>
            {!loading && <DownloadIcon stroke={'white'} />}
            {loading && <CircularProgress size={14} className={classes.buttonProgress} />}
          </Box>
          <Typography variant="body2" style={{ color: '#FFFFFF' }}>
            Download
          </Typography>
        </div>
        <Box className={classes.documentCardLargeFileIconWrapper}>
          <Box mb={2} style={{ opacity: 0.7 }}>
            <FileIcon size={100} fileExtension={document.fileExtension}></FileIcon>
          </Box>
        </Box>

        <CardContent style={{ display: 'flex', alignItems: 'center' }}>
          <Box className={classes.documentCardSmallFileIcon}>
            <FileIcon size={40} fileExtension={document.fileExtension}></FileIcon>
          </Box>
          <OverflowTooltip tooltip={`${document.title}.${document.fileExtension}`}>
            <Typography
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              color="textPrimary"
              component="p"
            >
              {document.title}.{document.fileExtension}
            </Typography>
          </OverflowTooltip>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default PublicationSupportingDocuments;
