import { Grid, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { BackdroppedCircularProgress, EmptyContainer, PublicationCard } from '../components';
import { InfiniteScroll } from '../components/InfiniteScroll';
import { useInfiniteScroll } from '../hooks/useInfiniteScroll';
import { PublicationListItem } from '../typings';
import { routes } from '../utils/constants';

const defaults = {
  pageSize: 10,
  sortFieldName: 'Name',
  sortDirection: 'DESC',
};

const Publications = () => {
  const location = useLocation();
  const showActivePublications = Boolean(useRouteMatch(routes.publications.active.routeProps));

  const filters = {
    ...defaults,
    active: showActivePublications,
  };

  const { records, hasMore, loading, error, setCursorRequest, loadMoreRef } = useInfiniteScroll<PublicationListItem>(
    'publications',
    filters
  );

  useEffect(() => {
    setCursorRequest(filters as any);
  }, [location]);

  const showAllActiveCompleted = !loading && !error && showActivePublications && records?.length === 0;
  const showAllArchivedCompleted = !loading && !error && !showActivePublications && records?.length === 0;
  const showError = !loading && error;
  const showData = !loading && records?.length !== 0;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="h4">
          <Switch>
            <Route {...routes.publications.active.routeProps}>Active Publications</Route>
            <Route {...routes.publications.archived.routeProps}>Archived Publications</Route>
          </Switch>
        </Typography>
      </Grid>
      <Grid item style={{ position: 'relative' }} xs={12}>
        <BackdroppedCircularProgress open={loading} />
        {showError && (
          <EmptyContainer icon={'error'} title={'Error when rendering Publications:'} message={error + ''} />
        )}
        {showAllActiveCompleted && <EmptyContainer icon={'check'} message={'All publications are completed!'} />}
        {showAllArchivedCompleted && <EmptyContainer icon={'empty'} message={'Nothing to show.'} />}
        {showData && records && (
          <>
            <Grid container spacing={4}>
              {records.map((item, key) => (
                <PublicationItem key={key} item={item} loading={loading} />
              ))}
            </Grid>
            <InfiniteScroll hasMore={hasMore} loadMoreRef={loadMoreRef} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

type PublicationItemProps = {
  item: PublicationListItem;
  loading: boolean;
};

const PublicationItem = ({ item, loading }: PublicationItemProps) => {
  const { id, target, openTaskCount, latestAvailableDocumentVersion } = item;
  const {
    fullyQualifiedName,
    products,
    documentType,
    documentTypeLabel,
    documentSubtype,
    documentSubtypeLabel,
    statusLabel,
    status,
    statusColor,
  } = latestAvailableDocumentVersion;

  return (
    <Grid item style={{ width: '100%' }} md={6}>
      <PublicationCard
        id={id}
        loading={loading}
        fullyQualifiedName={fullyQualifiedName}
        productName={products.map((product) => product.name).join(', ')}
        documentType={documentTypeLabel || documentType}
        documentSubtype={documentSubtypeLabel || documentSubtype}
        targetName={target?.name}
        targetDate={target?.targetDate}
        openTaskCount={openTaskCount}
        statusLabel={statusLabel || status}
        statusColor={statusColor}
        status={status}
      />
    </Grid>
  );
};

export default Publications;
