import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) =>
  createStyles({
    dialogPaper: {
      width: 'clamp(400px, 60vw, 749px)',
      // override mui's maxWidth
      maxWidth: 'clamp(400px, 60vw, 749px)',
      padding: theme.spacing(3),
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
    buttonsSection: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelButton: {
      marginRight: theme.spacing(2),
    },
    rejectButton: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      marginRight: theme.spacing(2),
    },
    inputLabelRoot: {
      fontSize: '14px',
      // replace primary color on hover
      '&.Mui-focused:not(.Mui-error)': { color: theme.palette.text.secondary },
      // replace error color
      '&.Mui-error': { color: theme.palette.text.secondary },
    },
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export default styles;
