import { DocumentVersion } from '../typings';
import { client } from './client';

/**
 * Converts a file to a base64 encoded string
 * @param file file to convert
 * @returns the base64 encoded string
 */
export const convertFileToBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result && (reader.result as string).split(',')[1];
      resolve(result);
    };
    reader.onabort = (error) => reject(error);
    reader.onerror = (error) => reject(error);
  });

/**
 * Formats the Download or Checkout URL details
 * @param version the document version record
 * @returns returns the download details
 */
export const getDownloadDetails = (version: DocumentVersion) => {
  if (!version) {
    return {
      downloadLabel: undefined,
      handleDownload: () => {},
      hasDownloadUrl: false,
    };
  }

  const isOffice365 = version.checkoutType === 'Office365';
  const hasDownloadUrl = !!((isOffice365 && version.checkoutUrl) || (!isOffice365 && version.downloadUrl));

  let downloadLabel: string;

  if (isOffice365) {
    downloadLabel = hasDownloadUrl ? 'Open in Office 365' : 'Checkout URL unavailable';
  } else {
    downloadLabel = hasDownloadUrl ? 'Download' : 'Download unavailable';
  }

  const handleDownload = async () => {
    const checkoutUrl = version.checkoutUrl;
    const downloadUrl = version.downloadUrl;

    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    } else if (downloadUrl) {
      window.location.href = client.getDocumentDownloadUrl(downloadUrl);
    }
  };

  return { downloadLabel, handleDownload, hasDownloadUrl };
};
