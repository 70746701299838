import { EmptyContainer } from '../components';

export const PageNotFound = () => (
  <EmptyContainer
    icon={'error'}
    title={'Page Not Found'}
    message={
      'The requested page could not be found. If you think this is a mistake, please, reach out to an administrator.'
    }
  />
);
