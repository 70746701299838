import { Box, Breadcrumbs, Button, Grid, Link, Tab, Tabs, Typography } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { BackdroppedCircularProgress, EmptyContainer, LabeledList, StatusLabel } from '../../../components';
import { PublicationDetailResponse } from '../../../typings';
import { client } from '../../../utils/client';
import { routes } from '../../../utils/constants';
import { getDownloadDetails } from '../../../utils/format';
import PublicationHistory from '../PublicationHistory/PublicationHistory';
import PublicationSupportingDocuments from '../PublicationSupportingDocuments';
import PublicationTasks from '../PublicationTasks';
import { useStyles } from './styles';

/**
 * @description Renders a page displaying PublicationDetails. Allows user to view associated Tasks, Document History, and Supporting Documents.
 *
 */
const PublicationDetails = () => {
  const match = useRouteMatch<{ id: string }>();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const classes = useStyles();

  const [{ loading, value: response, error }, doGetPublicationDetails] = useAsyncFn(() => {
    return client.getPublication({ id: match.params.id });
  }, [match.params.id]);

  useEffectOnce(() => {
    doGetPublicationDetails();
  });

  const { publication, history, myTasks, supportingDocuments } = response || ({} as PublicationDetailResponse);
  const target = publication?.target;
  const docVersion = publication?.latestAvailableDocumentVersion;

  const { downloadLabel, handleDownload, hasDownloadUrl } = getDownloadDetails(docVersion);

  const incompleteTasks = myTasks ? myTasks?.filter((task) => task.isOpen) : undefined;

  const openTaskCount = typeof incompleteTasks?.length === 'number' ? incompleteTasks.length : undefined;
  const docCount = typeof supportingDocuments?.length === 'number' ? supportingDocuments.length : undefined;

  const labeledItems = [
    { label: 'Product', value: docVersion?.products.map((product) => product.name).join(', ') },
    { label: 'Document Type', value: docVersion?.documentTypeLabel },
    { label: 'Document Subtype', value: docVersion?.documentSubtypeLabel },
    { label: 'Target Name', value: target?.name },
    { label: 'Target Date', value: target?.targetDate },
  ];

  const header = (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      {loading && <Skeleton height={30} width={600} />}
      {!loading && (
        <Breadcrumbs separator={<NavigateNext fontSize="large" />}>
          <Link
            to={
              publication?.active
                ? routes.publications.active.routeProps.path[0]
                : routes.publications.archived.routeProps.path[0]
            }
            component={RouterLink}
            variant="h4"
            color="textPrimary"
            className={classes.breadcrumbAnchor}
          >
            {!publication ? '...' : `${publication?.active ? 'Active' : 'Archived'} Publications`}
          </Link>
          <Typography variant="h4" color="textPrimary">
            {docVersion?.fullyQualifiedName ?? '...'}
            {!publication ? null : (
              <StatusLabel
                label={docVersion?.statusLabel || docVersion?.status}
                status={docVersion?.status}
                color={docVersion?.statusColor}
                style={{ marginLeft: 10 }}
              />
            )}
          </Typography>
        </Breadcrumbs>
      )}
      {!loading && (
        <Button color="secondary" variant="outlined" onClick={handleDownload} disabled={!hasDownloadUrl}>
          {downloadLabel}
        </Button>
      )}
    </Box>
  );

  if (error) {
    return <EmptyContainer icon={'error'} title={'Error when rendering Publication:'} message={error + ''} />;
  }

  return (
    <Grid container style={{ position: 'relative' }} spacing={5}>
      <BackdroppedCircularProgress open={loading} />
      <Grid item xs={12}>
        {header}
      </Grid>
      <Grid item md={6}>
        <LabeledList loading={loading} items={labeledItems} />
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={tabIndex}
          onChange={(_event, newValue: number) => setTabIndex(newValue)}
          textColor="secondary"
          indicatorColor="primary"
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
            flexContainer: classes.tabsFlexContainer,
          }}
          TabIndicatorProps={{ children: <span /> }}
        >
          <DetailsTab label={'My Tasks'} count={openTaskCount} />
          <DetailsTab label={'Document History'} />
          <DetailsTab label={'Supporting Documents'} count={docCount} />
        </Tabs>
        {publication && (
          <>
            <Box hidden={tabIndex !== 0}>
              <PublicationTasks tasks={myTasks} reloadPublication={doGetPublicationDetails} loading={loading} />
            </Box>
            <Box hidden={tabIndex !== 1}>
              <PublicationHistory history={history} />
            </Box>
            <Box hidden={tabIndex !== 2}>
              <PublicationSupportingDocuments documents={supportingDocuments} publicationId={publication.id} />
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
};

type DetailsTabProps = {
  label: string;
  count?: number;
};

const DetailsTab = (props: DetailsTabProps) => {
  const { label, count, ...tabProps } = props;
  const classes = useStyles();

  return (
    <Tab
      disableRipple
      classes={{ root: classes.tab }}
      label={`${label}${count !== undefined ? ` (${count})` : ''}`}
      {...tabProps}
    />
  );
};

export default PublicationDetails;
