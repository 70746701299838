import { useTheme } from '@material-ui/core/styles';

interface DownloadIconProps {
  stroke?: string;
  size?: string | number;
}

/**
 * Renders a Download Icon
 * @param props renderings properties
 * @returns the DownloadIcon component
 */
export const DownloadIcon = (props: DownloadIconProps) => {
  const theme = useTheme();
  const { stroke = theme.palette.primary.main, size = 14 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
      <polyline points="7 10 12 15 17 10"></polyline>
      <line x1="12" y1="15" x2="12" y2="3"></line>
    </svg>
  );
};
