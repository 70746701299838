import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    tasksCompleteCardContent: {
      padding: theme.spacing(5),
    },
    tasksCompleteIcon: {
      marginBottom: theme.spacing(1.5),
    },
  })
);
