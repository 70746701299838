import { CircularProgress } from '@material-ui/core';

type InfiniteScrollProps = {
  hasMore: boolean;
  loadMoreRef: React.LegacyRef<HTMLDivElement>;
};

export const InfiniteScroll = ({ hasMore, loadMoreRef }: InfiniteScrollProps) => {
  if (!hasMore) return <></>;

  return (
    <div style={{ textAlign: 'center', marginTop: 20 }} ref={loadMoreRef} data-testid={'infinite-scroll'}>
      <CircularProgress style={{ flex: 1 }} />
    </div>
  );
};
