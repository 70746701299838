import { useTheme } from '@material-ui/core/styles';

interface UploadIconProps {
  stroke?: string;
  size?: string | number;
}

export const UploadIcon = (props: UploadIconProps) => {
  const theme = useTheme();
  const { stroke = theme.palette.primary.main, size = 14 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
      <polyline points="17 8 12 3 7 8" />
      <line x1="12" y1="3" x2="12" y2="15" />
    </svg>
  );
};
