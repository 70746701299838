import { alpha, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbAnchor: {
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
    chip: {
      marginLeft: theme.spacing(2),
    },
    tab: {
      textTransform: 'none',
      padding: theme.spacing(1.5),
      paddingBottom: 0,
      paddingTop: 0,
      minWidth: 0,
      fontSize: 16,
      fontWeight: 600,
      minHeight: 0,
    },
    tabsRoot: {
      marginBottom: theme.spacing(2),
      minHeight: 0,
    },
    tabsFlexContainer: {
      borderBottom: `3px solid ${alpha(theme.palette.primary.main, 0.1)}`,
      '& >:not(:last-child)': {
        marginRight: theme.spacing(8),
      },
    },
    tabsIndicator: {
      display: 'flex',
      justifyContent: 'start',
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(1),
      '& > span': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        height: '3px', // matches bottomBorder of tabsFlexContainer
        position: 'relative',
        top: '-1px', // shift up 1px to align with bottomBorder of tabsFlexContainer
      },
    },
  })
);
