import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) =>
  createStyles({
    dropzoneRoot: {
      textAlign: 'center',
      height: '147px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px dashed #D4D4D4',
      borderRadius: '3px',
      backgroundColor: '#FFFFFF',
      color: '#bdbdbd',
      cursor: 'pointer',
    },

    uploadedFilesContainer: {
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  })
);

export default styles;
