import { Grid } from '@material-ui/core';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../App';
import { BackdroppedCircularProgress } from '../components';
import { client } from '../utils/client';

export const Callback = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code') as string;

  const history = useHistory();
  const [, setToken] = useContext(AuthContext);

  useEffect(() => {
    console.log('callback useEffect');
    if (code) {
      console.log('authorizing against Salesforce with', code);
      client
        .authorize(code)
        .then((newToken) => {
          setToken(newToken);
          console.log('token', newToken);
          console.log('redirecting to /...');

          history.replace('/');
        })
        .catch((err) => console.log('error when authing', err));
    }
  }, [code, history, setToken]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <BackdroppedCircularProgress open={true} />
      </Grid>
    </Grid>
  );
};
