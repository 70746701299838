import { useEffect, useState } from 'react';
import useInView from 'react-cool-inview';
import { SortDirection } from '../components/EnhancedTable';
import { CursorRequest, CursorResponse } from '../typings';
import { client } from '../utils/client';

export const useInfiniteScroll = <T>(path: 'publications' | 'tasks', filters: Record<string, any> = {}) => {
  const [cursorRequest, setCursorRequest] = useState<CursorRequest>(filters);
  const [queryingMore, setQueryingMore] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();
  const [records, setRecords] = useState<Array<T> | undefined>(undefined);
  const [nextRecordsUrl, setNextRecordsUrl] = useState<string | undefined>(undefined);

  const refresh = async () => {
    try {
      setLoading(true);

      const cursor = await client.list<T>(path, cursorRequest);

      setRecords(cursor.records);
      setNextRecordsUrl(cursor.nextRecordsUrl);
    } catch (err) {
      setError(err as any);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, [cursorRequest]);

  const { observe } = useInView({
    onEnter: () => queryMore(),
  });

  const queryMore = async () => {
    if (!nextRecordsUrl || queryingMore || loading) return;

    try {
      setQueryingMore(true);
      const newCursor = await client.do<CursorResponse<T>>(nextRecordsUrl);

      setRecords((existingRecords) => {
        if (existingRecords) {
          return [...existingRecords, ...newCursor.records];
        } else {
          return newCursor.records;
        }
      });

      setNextRecordsUrl(newCursor.nextRecordsUrl);
    } catch (err) {
      setError(err as any);
    } finally {
      setQueryingMore(false);
    }
  };

  const setSortFieldName = (sortFieldName: string) => {
    setCursorRequest((request) => ({ ...request, sortFieldName }));
  };

  const setSortDirection = (sortDirection: SortDirection) => {
    setCursorRequest((request) => ({ ...request, sortDirection: formatDirection(sortDirection) }));
  };

  return {
    error,
    hasMore: !!nextRecordsUrl,
    loading,
    queryingMore,
    records,
    refresh,
    setCursorRequest,
    setSortDirection,
    setSortFieldName,
    sortDirection: cursorRequest.sortDirection,
    sortFieldName: cursorRequest.sortFieldName,
    loadMoreRef: observe,
  };
};

const formatDirection = (direction: string) => {
  switch (direction) {
    case 'asc':
      return 'ASC';
    case 'desc':
      return 'DESC';
  }
};
