import { Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
  tooltip: string | number;
  children: JSX.Element;
}

/**
 * @description Renders a tooltip on hover if child element is overflowing horizontally.
 *  Inspired by: https://stackoverflow.com/a/62150782/1833014
 *
 * @example
 * <OverflowTooltip tooltip="This is the tooltip text">
 *   This is text which may display a tooltip on hover
 * </OverflowTooltip>
 *
 */
const OverflowTooltip = (props: Props) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);

  const checkOverflow = () => {
    const hasOverflow = (textElementRef?.current?.scrollWidth ?? 0) > (textElementRef?.current?.clientWidth ?? 0);

    setHoverListener(hasOverflow);
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  const [hoverListener, setHoverListener] = useState(false);

  const childElement = React.Children.only(props.children);

  return (
    <Tooltip
      placement="top"
      title={<Typography>{props.tooltip}</Typography>}
      interactive
      disableHoverListener={!hoverListener}
    >
      {React.cloneElement(childElement, { ref: textElementRef })}
    </Tooltip>
  );
};

export default OverflowTooltip;
