import { Box, Button, Container, Link } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

export const AppFooter = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <Box className={classes.root}>
      <Container>
        <Box className={classes.section} pb={2}>
          <Box>Powered by</Box>
          <Box flex={1} ml={1}>
            <Link href="https://mavens.com" color={'inherit'} underline={'hover'} target={'_blank'}>
              <img src={'/mavens-logo.png'} alt={'Mavens Logo'} width="100" height="34.21" />
            </Link>
          </Box>
          <Button href={`https://mavens.com/privacy-policy/`} target={'_blank'}>
            Privacy Policy
          </Button>
        </Box>
        <Box className={classes.section}>
          <Box flex={1} textAlign={'center'}>
            &copy; 2007-{currentYear} Mavens Komodo Health, Inc.
            <br />
            All rights reserved. Various trademarks held by their respective owners.
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
