import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { OverflowTooltip } from '.';

const useStyles = makeStyles((theme) =>
  createStyles({ text: { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' } })
);

export interface LabeledListProps {
  loading: boolean;
  items: Array<{ label: string; value: string | number | undefined }>;
}

/**
 * @description Renders a vertical list of labels and values.
 *
 * @example
 * <LabeledList items={[{label: 'I am the label', value: 'I am the value'}]} />
 *
 */
const LabeledList = (props: LabeledListProps) => {
  const classes = useStyles();
  const { items, loading } = props;

  return (
    <Grid container>
      {items.map((item) => (
        <React.Fragment key={item.label}>
          <Grid item xs={4}>
            <OverflowTooltip tooltip={item.label}>
              <Typography variant="body1" color="textSecondary" className={classes.text}>
                {item.label}
              </Typography>
            </OverflowTooltip>
          </Grid>
          <Grid item xs={8}>
            {loading && <Skeleton height={10} width="80%" />}
            {!loading && (
              <OverflowTooltip tooltip={item.value ?? ''}>
                <Typography variant="body1" color="textSecondary" className={classes.text}>
                  {item.value && item.value}
                  {!item.value && <span>&mdash;</span>}
                </Typography>
              </OverflowTooltip>
            )}
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default LabeledList;
