import { Backdrop, BackdropProps, CircularProgress } from '@material-ui/core';

/**
 * @description Renders a indeterminate circular progress bar centered in a backdrop.
 *  Note: To contain backdrop in parent, parent must have css style `position: 'relative;`
 */
const BackdroppedCircularProgress = (props: BackdropProps) => (
  <Backdrop style={{ position: 'absolute', zIndex: 1 }} {...props}>
    <CircularProgress data-testid="loading" role="progressbar" />
  </Backdrop>
);

export default BackdroppedCircularProgress;
