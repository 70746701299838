/**
 * Token sessionStorage key name
 */
const TOKEN_KEY = 'token';

/**
 * Persist the token to session storage
 * @param userToken OAuth2 token
 */
export const setToken = (userToken: Record<string, string | undefined> | undefined) => {
  if (userToken) {
    sessionStorage.setItem(TOKEN_KEY, JSON.stringify(userToken));
  } else {
    sessionStorage.removeItem(TOKEN_KEY);
  }
};

/**
 * Returns token from session storage
 * @param userToken OAuth2 token
 * @returns the token
 */
export const getToken = () => {
  const tokenString = sessionStorage.getItem(TOKEN_KEY) as string;
  return JSON.parse(tokenString);
};
