import { useEffect } from 'react';
import { client } from '../utils/client';
import { EmptyContainer } from './';

/**
 * Redirects the running user to the authorization url
 * @returns the component
 */
export const RedirectToLogin = () => {
  useEffect(() => {
    window.location.href = client.getAuthorizationUrl();
  });

  return <EmptyContainer icon={'login'} message={'Redirecting to login page...'} />;
};
