import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';
import { RedirectToLogin } from './components/RedirectToLogin';
import { RedirectToLogout } from './components/RedirectToLogout';
import { Profile, PublicationDetails, Publications, Tasks } from './pages';
import { Callback } from './pages/Callback';
import { PageNotFound } from './pages/PageNotFound';
import { routes } from './utils/constants';

export const Router = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/">
        <Redirect to="/publications" />
      </PrivateRoute>
      <PrivateRoute {...routes.tasks.routeProps}>
        <Tasks />
      </PrivateRoute>
      <PrivateRoute {...routes.publications.routeProps}>
        <Publications />
      </PrivateRoute>
      <PrivateRoute {...routes.publicationDetails.routeProps}>
        <PublicationDetails />
      </PrivateRoute>
      <PrivateRoute {...routes.profile.routeProps}>
        <Profile />
      </PrivateRoute>
      <Route {...routes.callback.routeProps}>
        <Callback />
      </Route>
      <Route {...routes.login.routeProps}>
        <RedirectToLogin />
      </Route>
      <Route {...routes.logout.routeProps}>
        <RedirectToLogout />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};
