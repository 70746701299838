import { useState } from 'react';
import jsforce from 'jsforce';
import { client } from '../utils/client';
import * as actions from '../utils/persist';
import { getInstanceConfiguration } from '../utils/domain-mapping';

export const useToken = () => {
  const getToken = (): jsforce.ConnectionOptions => {
    const tokenString = sessionStorage.getItem('token') as string;
    const userToken = JSON.parse(tokenString) as jsforce.ConnectionOptions;

    if (userToken) {
      client.setToken({ ...userToken, ...getInstanceConfiguration() });
    }

    return userToken;
  };

  const [token, setToken] = useState<jsforce.ConnectionOptions>(getToken());

  const saveToken = (userToken: Record<string, string | undefined>) => {
    actions.setToken(userToken);
    setToken(userToken);
  };

  return [token, saveToken];
};
