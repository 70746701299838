import { CLIENT_ID, INSTANCE_URL } from './constants';

/**
 * A customer instance configuration
 */
type InstanceConfiguration = {
  clientId: string;
  loginUrl: string;
  instanceUrl?: string;
};

/**
 * Returns the instance configuration, or default configuration
 * @returns the instance configuration
 */
export const getInstanceConfiguration = (): InstanceConfiguration => {
  const clientId = CLIENT_ID;
  const instanceUrl = INSTANCE_URL;
  const loginUrl = instanceUrl;

  return { clientId, loginUrl, instanceUrl };
};
