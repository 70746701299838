import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    actionButton: {
      fontFamily: 'NotoSans-SemiBold',
      justifyContent: 'space-between',
    },
    cardTitle: {
      marginRight: theme.spacing(2),
    },
    cardTitleAnchor: {
      fontFamily: 'NotoSans-Light',
      color: '#111111',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
    cardText: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    cardDivider: {
      backgroundColor: '#F2F2F2',
    },
  })
);
